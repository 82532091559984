import React from 'react';
import { ViewProvider } from './contexts/ViewContext';
import { MainApp } from './MainApp';

function App() {
  return (
    <ViewProvider>
      <MainApp />
    </ViewProvider>
  );
}

export default App;