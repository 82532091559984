import React from 'react';
import { motion } from 'framer-motion';
import { Code2, Cpu, Database } from 'lucide-react';
import { ParticlesBackground } from './ParticlesBackground';
import { useTranslation } from 'react-i18next';

export const Hero = () => {
  const { t } = useTranslation();
  
  return (
    <section className="relative min-h-screen bg-black text-white overflow-hidden">
      <ParticlesBackground />
      
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="relative z-10 container mx-auto px-4 pt-32 pb-20"
      >
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.8 }}
          className="flex flex-col items-center"
        >
          <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold mb-8 text-center leading-tight">
            <span className="text-[#00ff00]">Vexora</span>
            <span className="text-white">.Digital</span>
          </h1>
          <div className="w-24 h-1 bg-[#00ff00] mb-8" />
        </motion.div>
        
        <motion.p 
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.8 }}
          className="text-lg md:text-xl lg:text-2xl text-gray-300 max-w-3xl mx-auto text-center mb-12 font-mono"
        >
          {t('hero.subtitle')}
          <br />
          <span className="text-[#00ff00]">{t('hero.tagline')}</span>
        </motion.p>

        <motion.div 
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.8 }}
          className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 mt-16"
        >
          {[
            {
              icon: <Code2 className="w-8 h-8 md:w-12 md:h-12" />,
              key: "development"
            },
            {
              icon: <Cpu className="w-8 h-8 md:w-12 md:h-12" />,
              key: "architecture"
            },
            {
              icon: <Database className="w-8 h-8 md:w-12 md:h-12" />,
              key: "integration"
            }
          ].map((service) => (
            <motion.div
              key={service.key}
              whileHover={{ scale: 1.05 }}
              className="p-4 md:p-6 rounded-lg border border-[#00ff00]/20 bg-black/50 backdrop-blur-sm hover:border-[#00ff00]/40 transition-colors"
            >
              <div className="text-[#00ff00] mb-4">{service.icon}</div>
              <h2 className="text-lg md:text-xl font-bold mb-2 font-mono">
                {t(`hero.services.${service.key}.title`)}
              </h2>
              <p className="text-gray-400 text-sm md:text-base">
                {t(`hero.services.${service.key}.desc`)}
              </p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-transparent" />
    </section>
  );
};