import React from 'react';
import { Code2, Mail, MapPin, Clock } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  
  return (
    <footer className="bg-black border-t border-[#00ff00]/20 relative z-50">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div>
            <div className="flex items-center space-x-2 mb-4">
              <Code2 className="w-6 h-6 text-[#00ff00]" />
              <span className="text-2xl font-bold font-mono">vorexa.design</span>
            </div>
            <p className="text-gray-400 mb-4">
              {t('footer.description')}
            </p>
          </div>
          
          <div>
            <h3 className="text-lg font-bold mb-4 font-mono">{t('footer.contact')}</h3>
            <div className="space-y-3">
              <div className="flex items-center space-x-2 text-gray-400">
                <Mail className="w-5 h-5 text-[#00ff00]" />
                <a href="mailto:support@vorexa.design" className="hover:text-[#00ff00] transition-colors">
                  support@vorexa.design
                </a>
              </div>
              <div className="flex items-center space-x-2 text-gray-400">
                <MapPin className="w-5 h-5 text-[#00ff00]" />
                <span>Europe</span>
              </div>
              <div className="flex items-center space-x-2 text-gray-400">
                <Clock className="w-5 h-5 text-[#00ff00]" />
                <span>{t('footer.businessHours')}</span>
              </div>
            </div>
          </div>
          
          <div>
            <h3 className="text-lg font-bold mb-4 font-mono">{t('footer.quickLinks')}</h3>
            <ul className="space-y-2">
              {['services', 'packages', 'portfolio', 'contact'].map((item) => (
                <li key={item}>
                  <a
                    href={`#${item}`}
                    className="text-gray-400 hover:text-[#00ff00] transition-colors"
                  >
                    {t(`header.${item}`)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        
        <div className="pt-8 border-t border-[#00ff00]/20">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-400 text-sm mb-4 md:mb-0">
              © {new Date().getFullYear()} Vexora Digital Europe. {t('footer.rights')}
            </div>
            <div className="text-sm text-gray-400">
              {t('footer.madeWith')}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};