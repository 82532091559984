import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Terminal, Check, Info, X } from 'lucide-react';
import { ContactForm } from './ContactForm';
import { useTranslation } from 'react-i18next';

interface Package {
  name: string;
  price: string;
  description: string;
  idealFor: string[];
  features: string[];
  requirements: string[];
  timeframe: string;
}

export const Packages = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const [selectedPackage, setSelectedPackage] = React.useState<string | null>(null);
  const [showDetails, setShowDetails] = React.useState<string | null>(null);
  const { t } = useTranslation();

  const packages = React.useMemo<Package[]>(() => {
    try {
      return [
        {
          name: t('packages.basic.name'),
          price: '850',
          description: t('packages.basic.description'),
          idealFor: Array.isArray(t('packages.basic.idealFor', { returnObjects: true })) 
            ? t('packages.basic.idealFor', { returnObjects: true }) 
            : [],
          features: Array.isArray(t('packages.basic.features', { returnObjects: true }))
            ? t('packages.basic.features', { returnObjects: true })
            : [],
          requirements: Array.isArray(t('packages.basic.requirements', { returnObjects: true }))
            ? t('packages.basic.requirements', { returnObjects: true })
            : [],
          timeframe: t('packages.basic.timeframe')
        },
        {
          name: t('packages.advanced.name'),
          price: '1,500',
          description: t('packages.advanced.description'),
          idealFor: Array.isArray(t('packages.advanced.idealFor', { returnObjects: true }))
            ? t('packages.advanced.idealFor', { returnObjects: true })
            : [],
          features: Array.isArray(t('packages.advanced.features', { returnObjects: true }))
            ? t('packages.advanced.features', { returnObjects: true })
            : [],
          requirements: Array.isArray(t('packages.advanced.requirements', { returnObjects: true }))
            ? t('packages.advanced.requirements', { returnObjects: true })
            : [],
          timeframe: t('packages.advanced.timeframe')
        },
        {
          name: t('packages.elite.name'),
          price: '2,500',
          description: t('packages.elite.description'),
          idealFor: Array.isArray(t('packages.elite.idealFor', { returnObjects: true }))
            ? t('packages.elite.idealFor', { returnObjects: true })
            : [],
          features: Array.isArray(t('packages.elite.features', { returnObjects: true }))
            ? t('packages.elite.features', { returnObjects: true })
            : [],
          requirements: Array.isArray(t('packages.elite.requirements', { returnObjects: true }))
            ? t('packages.elite.requirements', { returnObjects: true })
            : [],
          timeframe: t('packages.elite.timeframe')
        }
      ];
    } catch (error) {
      console.error('Error loading packages:', error);
      return [];
    }
  }, [t]);

  if (!packages.length) {
    return null;
  }

  return (
    <section className="py-20 bg-black text-white" id="packages">
      <div className="container mx-auto px-4">
        <motion.div
          ref={ref}
          initial={{ y: 50, opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : {}}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <Terminal className="w-16 h-16 mx-auto mb-6 text-[#00ff00]" />
          <h2 className="text-4xl md:text-5xl font-bold mb-6 font-mono">
            {t('packages.title.select')} <span className="text-[#00ff00]">{t('packages.title.package')}</span>
          </h2>
          <p className="text-xl text-gray-400 font-mono max-w-2xl mx-auto">
            {t('packages.subtitle')}
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {packages.map((pkg, index) => (
            <motion.div
              key={pkg.name}
              initial={{ y: 50, opacity: 0 }}
              animate={inView ? { y: 0, opacity: 1 } : {}}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              className="relative p-8 rounded-lg border border-[#00ff00]/20 bg-black/50 backdrop-blur-sm hover:border-[#00ff00]/40 transition-all"
            >
              <div className="relative">
                <h3 className="text-2xl font-bold mb-4 font-mono">{pkg.name}</h3>
                <div className="text-4xl font-bold mb-4 font-mono">
                  €{pkg.price}
                  <span className="text-lg font-normal text-gray-400">/{t('packages.perProject')}</span>
                </div>
                <p className="text-gray-400 mb-6 text-sm">{pkg.description}</p>
                
                <div className="mb-6">
                  <h4 className="text-sm font-bold mb-2 text-[#00ff00]">{t('packages.idealFor')}:</h4>
                  <ul className="space-y-2">
                    {pkg.idealFor.map((item) => (
                      <li key={item} className="flex items-center text-sm">
                        <Check className="w-4 h-4 mr-2 text-[#00ff00]" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mb-6">
                  <h4 className="text-sm font-bold mb-2 text-[#00ff00]">{t('packages.features')}:</h4>
                  <ul className="space-y-2">
                    {pkg.features.map((feature) => (
                      <li key={feature} className="flex items-center text-sm">
                        <span className="text-[#00ff00] mr-2">{'>'}</span>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="text-sm text-gray-400 mb-6">
                  <span className="text-[#00ff00]">{t('packages.timeframe')}:</span> {pkg.timeframe}
                </div>

                <button
                  onClick={() => setShowDetails(pkg.name)}
                  className="w-full py-2 px-4 text-center rounded border border-[#00ff00]/50 text-[#00ff00] hover:bg-[#00ff00]/10 transition-all duration-300 mb-4 text-sm font-mono"
                >
                  <Info className="w-4 h-4 inline-block mr-2" />
                  {t('packages.viewRequirements')}
                </button>

                <button
                  onClick={() => setSelectedPackage(pkg.name)}
                  className="w-full py-3 px-6 text-center rounded border border-[#00ff00] text-[#00ff00] hover:bg-[#00ff00] hover:text-black transition-all duration-300 font-mono"
                >
                  {t('packages.selectPackage')}
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        {showDetails && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4"
          >
            <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full relative border border-[#00ff00]/20">
              <button
                onClick={() => setShowDetails(null)}
                className="absolute top-4 right-4 text-gray-400 hover:text-white"
              >
                <X />
              </button>
              
              <h3 className="text-2xl font-bold mb-6 font-mono">
                {showDetails} {t('packages.requirements')}
              </h3>
              
              <div className="space-y-4">
                {packages.find(p => p.name === showDetails)?.requirements.map((req) => (
                  <div key={req} className="flex items-start">
                    <Check className="w-5 h-5 mr-3 text-[#00ff00] mt-1" />
                    <p className="text-gray-300">{req}</p>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        )}

        {selectedPackage && (
          <ContactForm selectedPackage={selectedPackage} onClose={() => setSelectedPackage(null)} />
        )}
      </div>
    </section>
  );
};