import React from 'react';
import { Smartphone, Monitor } from 'lucide-react';
import { useView } from '../contexts/ViewContext';
import { motion } from 'framer-motion';

export const ViewToggle = () => {
  const { viewMode, toggleViewMode } = useView();

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={toggleViewMode}
      className="flex items-center space-x-2 px-3 py-2 rounded-full border border-[#00ff00]/20 hover:border-[#00ff00] bg-black/50 text-[#00ff00] transition-colors"
      title={viewMode === 'desktop' ? 'Switch to mobile view' : 'Switch to desktop view'}
    >
      {viewMode === 'desktop' ? (
        <>
          <Smartphone className="w-4 h-4" />
          <span className="text-sm">Mobile View</span>
        </>
      ) : (
        <>
          <Monitor className="w-4 h-4" />
          <span className="text-sm">Desktop View</span>
        </>
      )}
    </motion.button>
  );
};