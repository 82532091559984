import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Code2, Menu, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from './LanguageSwitcher';
import { ViewToggle } from './ViewToggle';

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header 
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? 'bg-black/90 backdrop-blur-md py-4' : 'bg-transparent py-6'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between">
          <motion.a
            href="/"
            className="flex items-center space-x-2"
            whileHover={{ scale: 1.05 }}
          >
            <Code2 className="w-8 h-8 text-[#00ff00]" />
            <span className="text-2xl font-bold font-mono">
              <span className="text-[#00ff00]">Vexora</span>
              <span className="text-white">.Digital</span>
            </span>
          </motion.a>

          <div className="hidden md:flex items-center space-x-8">
            <nav className="flex items-center space-x-8">
              {['services', 'packages', 'portfolio', 'contact'].map((item) => (
                <motion.a
                  key={item}
                  href={`#${item}`}
                  className="text-white hover:text-[#00ff00] transition-colors font-mono"
                  whileHover={{ scale: 1.1 }}
                >
                  {t(`header.${item}`)}
                </motion.a>
              ))}
            </nav>
            <div className="flex items-center space-x-4">
              <ViewToggle />
              <LanguageSwitcher />
            </div>
          </div>

          <div className="md:hidden flex items-center space-x-4">
            <ViewToggle />
            <button
              className="text-white"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>

        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="md:hidden absolute top-full left-0 right-0 bg-black/95 backdrop-blur-lg border-t border-[#00ff00]/20 py-4"
          >
            <nav className="flex flex-col space-y-4 px-4">
              {['services', 'packages', 'portfolio', 'contact'].map((item) => (
                <a
                  key={item}
                  href={`#${item}`}
                  className="text-white hover:text-[#00ff00] transition-colors font-mono py-2"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {t(`header.${item}`)}
                </a>
              ))}
              <div className="py-2">
                <LanguageSwitcher />
              </div>
            </nav>
          </motion.div>
        )}
      </div>
    </header>
  );
};