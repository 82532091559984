import React from 'react';
import { motion } from 'framer-motion';
import { Mail, MessageSquare, Clock } from 'lucide-react';

export const Contact = () => {
  return (
    <section className="py-20 bg-black text-white" id="contact">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="max-w-5xl mx-auto"
        >
          <div className="text-center mb-16">
            <h2 className="text-4xl md:text-5xl font-bold mb-6 font-mono">
              Get in <span className="text-[#00ff00]">Touch</span>
            </h2>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto font-mono">
              Ready to transform your digital presence? Let's discuss your project.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            {[
              {
                icon: <Mail className="w-8 h-8" />,
                title: "Email Us",
                content: "support@vorexa.design",
                link: "mailto:support@vorexa.design"
              },
              {
                icon: <MessageSquare className="w-8 h-8" />,
                title: "Quick Response",
                content: "Within 24 hours"
              },
              {
                icon: <Clock className="w-8 h-8" />,
                title: "Business Hours",
                content: "Mon - Fri, 9:00 - 18:00"
              }
            ].map((item) => (
              <motion.div
                key={item.title}
                whileHover={{ scale: 1.05 }}
                className="p-6 rounded-lg border border-[#00ff00]/20 bg-black/50 backdrop-blur-sm hover:border-[#00ff00]/40 transition-all text-center"
              >
                <div className="text-[#00ff00] mb-4 flex justify-center">
                  {item.icon}
                </div>
                <h3 className="text-xl font-bold mb-2 font-mono">{item.title}</h3>
                {item.link ? (
                  <a
                    href={item.link}
                    className="text-gray-400 hover:text-[#00ff00] transition-colors"
                  >
                    {item.content}
                  </a>
                ) : (
                  <p className="text-gray-400">{item.content}</p>
                )}
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};