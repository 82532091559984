import React from 'react';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import { X, Loader2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface ContactFormProps {
  selectedPackage: string;
  onClose: () => void;
}

export const ContactForm: React.FC<ContactFormProps> = ({ selectedPackage, onClose }) => {
  const [status, setStatus] = React.useState<'idle' | 'sending' | 'success' | 'error'>('idle');
  const formRef = React.useRef<HTMLFormElement>(null);
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formRef.current) return;

    setStatus('sending');
    
    try {
      await emailjs.sendForm(
        'service_b5jozed',
        'template_bduj9kd',
        formRef.current,
        '-PVZ_kytz-jJ8q0J-'
      );
      setStatus('success');
      setTimeout(onClose, 2000);
    } catch (error) {
      console.error('EmailJS error:', error);
      setStatus('error');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-gray-900 p-8 rounded-lg max-w-md w-full relative border border-[#00ff00]/20"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <X />
        </button>
        
        <h3 className="text-2xl font-bold mb-6 font-mono">
          {t('form.request')} <span className="text-[#00ff00]">{selectedPackage}</span> {t('form.package')}
        </h3>
        
        <form ref={formRef} onSubmit={handleSubmit} className="space-y-4">
          <input type="hidden" name="package" value={selectedPackage} />
          
          <div>
            <label className="block text-sm font-mono mb-2">{t('form.companyName')}</label>
            <input
              type="text"
              name="company"
              required
              className="w-full bg-black border border-[#00ff00]/20 rounded p-2 text-white focus:border-[#00ff00] focus:outline-none font-mono"
            />
          </div>

          <div>
            <label className="block text-sm font-mono mb-2">{t('form.contactPerson')}</label>
            <input
              type="text"
              name="name"
              required
              className="w-full bg-black border border-[#00ff00]/20 rounded p-2 text-white focus:border-[#00ff00] focus:outline-none font-mono"
            />
          </div>
          
          <div>
            <label className="block text-sm font-mono mb-2">{t('form.email')}</label>
            <input
              type="email"
              name="email"
              required
              className="w-full bg-black border border-[#00ff00]/20 rounded p-2 text-white focus:border-[#00ff00] focus:outline-none font-mono"
            />
          </div>

          <div>
            <label className="block text-sm font-mono mb-2">{t('form.phone')}</label>
            <input
              type="tel"
              name="phone"
              className="w-full bg-black border border-[#00ff00]/20 rounded p-2 text-white focus:border-[#00ff00] focus:outline-none font-mono"
            />
          </div>

          <div>
            <label className="block text-sm font-mono mb-2">{t('form.timeline')}</label>
            <select
              name="timeline"
              required
              className="w-full bg-black border border-[#00ff00]/20 rounded p-2 text-white focus:border-[#00ff00] focus:outline-none font-mono"
            >
              <option value="">{t('form.selectTimeline')}</option>
              <option value="immediate">{t('form.timelineOptions.immediate')}</option>
              <option value="1month">{t('form.timelineOptions.oneMonth')}</option>
              <option value="3months">{t('form.timelineOptions.threeMonths')}</option>
              <option value="flexible">{t('form.timelineOptions.flexible')}</option>
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-mono mb-2">{t('form.projectDetails')}</label>
            <textarea
              name="message"
              rows={4}
              required
              placeholder={t('form.projectDetailsPlaceholder')}
              className="w-full bg-black border border-[#00ff00]/20 rounded p-2 text-white focus:border-[#00ff00] focus:outline-none font-mono"
            />
          </div>
          
          <button
            type="submit"
            disabled={status === 'sending'}
            className="w-full py-3 px-6 bg-[#00ff00] text-black rounded font-mono hover:bg-[#00ff00]/90 transition-colors disabled:opacity-50 flex items-center justify-center"
          >
            {status === 'sending' ? (
              <>
                <Loader2 className="animate-spin mr-2" />
                {t('form.sending')}
              </>
            ) : status === 'success' ? (
              t('form.success')
            ) : status === 'error' ? (
              t('form.error')
            ) : (
              t('form.submit')
            )}
          </button>

          <p className="text-sm text-gray-400 text-center mt-4">
            {t('form.response')}
          </p>
        </form>
      </motion.div>
    </motion.div>
  );
};