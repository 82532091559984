import React, { Suspense } from 'react';
import { useView } from './contexts/ViewContext';
import DesktopApp from './DesktopApp';

const MobileApp = React.lazy(() => import('./mobile/App'));

export const MainApp = () => {
  const { viewMode } = useView();
  
  return (
    <div className="min-h-screen flex flex-col">
      {viewMode === 'desktop' ? (
        <DesktopApp />
      ) : (
        <Suspense fallback={
          <div className="flex-1 flex items-center justify-center">
            <div className="text-[#00ff00] animate-pulse">Loading mobile view...</div>
          </div>
        }>
          <MobileApp />
        </Suspense>
      )}
    </div>
  );
};